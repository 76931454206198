// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";
  
// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
   
    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.inactive-row td {
  color: #00F;
}

.contentDash {
  font-size: 50px;
}

.truncate p {
  margin-bottom: 0;
  cursor: zoom-in;
}

.pointer {
  cursor: pointer;
}

.btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-primary:focus:not(.btn-text), .btn.btn-primary.focus:not(.btn-text){
  border-color: #81ba26;
  color: #81ba26;
}

h5{
  font-size: 1.1rem;
}

input{
  padding-top: 12.5px !important;
  padding-right: 10px !important;
  padding-bottom: 12.5px !important;
  padding-left: 10px !important;
  font-size: 13px !important;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
  padding: 0px !important;
}
.MuiOutlinedInput-multiline {
  padding: 12.5px 10px !important;
  font-size: 13px;
}
.card-header{
  position: sticky;
  top: 0px;
  background-color: white !important;
  z-index: 1000;
}
.my-custom-zindex {
  z-index: 10000 !important;
}
.customDialog {
  z-index: 2 !important;
}
.card.card-custom > .card-header{
  z-index: 97;
}
.MuiInputLabel-formControl {
  font-size: 13.5px !important;
  font-weight: bold !important;
  transform: translate(0, 0px) scale(1) !important;
  z-index: 100;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
  background-color: #17c191;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined .MuiButton-label {
  color: white;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
  background-color: black;
  border-color: transparent !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined:hover {
  text-decoration: none;
  background-color: rgba(60, 60, 60, 0.68) !important;
}
button[class*="MuiButtonBase-root"][class*="MuiButton-root"][class*="MuiButton-outlined"][class*="MuiButton-outlinedSecondary"] {
  border-color: transparent;
  background-color: #F64E60;
}
button[class*="MuiButtonBase-root"][class*="MuiButton-root"][class*="MuiButton-outlined"][class*="MuiButton-outlinedSecondary"]:hover {
  text-decoration: none;
  background-color: #fc8b96;
}
button[class*="MuiButtonBase-root"][class*="MuiButton-root"][class*="MuiButton-outlined"][class*="MuiButton-outlinedSecondary"] span[class*="MuiButton-label"] {
  color: white;
}
.MuiInput-underline:before {
  top: 0;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.42);
}
.MuiInput-underline:after {
  top: 0;
  border-radius: 7px;
  border: 1px solid #17c191;
  border-bottom: 1px solid #17c191;
  transition: none !important;
}
label + .MuiInput-formControl {
  margin-top: 20px !important;
}
.MuiInputBase-inputMultiline {
  padding: 7px !important;
  font-size: 13px !important;
}
.label-editor {
  font-size: 13.5px !important;
  font-weight: bold !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
}
.ck.ck-reset.ck-editor.ck-rounded-corners {
  top: 13px !important;
}
.ck-restricted-editing_mode_standard.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  border-color: #17c191;
}
label.label-editor.label-focused {
  color: #17c191;
}
.readonly {
  background-color: white !important;
}
.readonly .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  background-color: #dfdfdf;
  border-radius: 10px;
}
label#adjustedWeight-label {
  z-index: 1 !important;
}
label#idealWeight-label {
  z-index: 1 !important;
}
.MuiInputLabel-shrink {
  transform: none !important;
}
.MuiButton-outlinedSecondary {
  background-color: #3783e7 !important;
  color: white !important;
}
.MuiButton-outlinedSecondary:hover {
  border: 1px solid #3783e7 !important;
  background-color: #9dbce5 !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary:hover {
  text-decoration: none;
  background-color: rgb(82 243 184 / 68%) !important;
}
a.fc-event-start.fc-event-end.fc-daygrid-event.fc-daygrid-block-event.fc-h-event {
  background-color: transparent;
  border-color: transparent;
}