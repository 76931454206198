.ck-editor__editable_inline {
  min-height: 100px;
}

.custom-editor{
  position: relative;
  margin-top: 10px;
}

.label-editor{
  background-color: white;
  padding-left: 5px !important;
  padding-right: 5px !important;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1;
  position: absolute;
  z-index: 1;
  margin-left: 8px;
  top: -5px;
}

.custom-editor.max-height .ck-editor__editable_inline {
  max-height: 500px;
}

.custom-editor.half-width{
  width: 50%;
}

.ck-editor i {
  font-size: unset !important;
  color: unset !important;
}

.readonly{
  background-color: #dfdfdf;
}